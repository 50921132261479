import { Controller } from "@hotwired/stimulus"
import { t as globalT } from "helpers/i18n"

export default class extends Controller {
  static targets = ["button", "costs"]

  toggle(event) {
    const isHidden = this.buttonTarget.innerText === globalT("js.timesheets.index.show")

    this.costsTargets.forEach((element) => {
      element.classList.toggle("hidden", !isHidden) // Show if currently hidden, hide if currently visible
    })

    this.buttonTarget.innerText = isHidden ? globalT("js.timesheets.index.hide") : globalT("js.timesheets.index.show")
  }
}
