import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    id: String,
  }

  // This fires on initial load but also whenever a change is submitted, since it rerenders that part of the dom using turbo.
  // On initial load the request this event triggers will short circuit due to no selected leave type so it doesn't matter that it fires.
  connect() {
    const breakdownHoursChangeEvent = new CustomEvent("breakdownHoursChange")
    window.dispatchEvent(breakdownHoursChangeEvent)
  }

  trackUpdates(e) {
    this.dispatch(`${this.idValue}-dailyBreakdownUpdated`)
    const thisModal = document.getElementById(`${this.idValue}_leave_modal`)
    const closeBtn = thisModal.shadowRoot.querySelector(`.corner-close-btn`)
    closeBtn.style.display = "none"
  }
}
