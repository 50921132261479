import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["conditional", "require"]

  initialize() {
    this.updateConditionalTargets()
  }

  updateConditionalTargets() {
    this.conditionalTargets.forEach((conditionalTarget) => {
      const isRequired = this.checkIfAnyRequireTargetsAreChecked()
      conditionalTarget.required = isRequired
      conditionalTarget.disabled = !isRequired
    })
  }

  checkIfAnyRequireTargetsAreChecked() {
    return this.requireTargets.some((target) => {
      if (target.type === "radio" || target.type === "checkbox") {
        return target.checked
      } else {
        return false
      }
    })
  }
}
