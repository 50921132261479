import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    departments: Object,
  }

  static targets = ["teamSelect"]

  onLocationChange(event) {
    const teams = this.departmentsValue[event.target.value]
    this.teamSelectTarget.length = 0
    teams.forEach(([team, id]) => {
      this.teamSelectTarget.add(new Option(team, id))
    })
    this.teamSelectTarget.focus()
  }
}
