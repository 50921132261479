import { Controller } from "@hotwired/stimulus"

// TODO merge the mobile form/controller into this one
export default class extends Controller {
  static values = {
    visibleToEmployee: Boolean,
  }

  static targets = ["acknowledgementRequired"]

  connect() {
    this.setAckTargetEnabled(this.visibleToEmployeeValue)
  }

  onVisibilityChange(event) {
    this.setAckTargetEnabled(event.target.checked)
  }

  setAckTargetEnabled(enabled) {
    if (enabled) {
      this.acknowledgementRequiredTarget.removeAttribute("disabled")
    } else {
      this.acknowledgementRequiredTarget.checked = false
      this.acknowledgementRequiredTarget.setAttribute("disabled", "true")
    }
  }
}
