const colors = require("tailwindcss/colors")
const plugin = require("tailwindcss/plugin")
const defaultTheme = require("tailwindcss/defaultTheme")

module.exports = {
  content: [
    // Docs: https://tailwindcss.com/docs/content-configuration#configuring-source-paths
    // Not every view uses Tailwind, so this will potentially return more classes than we need.
    // But it is simpler than the alternative of having to update this list every time we adopt Tailwind somewhere.
    // The file size will be slightly larger, but we already ship a lot of assets... this isn't the biggest problem.
    // And over time as Tailwind adoption improves the generated CSS will get more correct.
    "./app/views/**/**",
    "./app/helpers/**/**",
    "./app/assets/webpack/mobile_app/helpers/flash.js",
    /* Stimulus controllers can reference Tailwind, although the classes API is preferred: https://stimulus.hotwired.dev/reference/css-classes */
    "./app/assets/webpack/stimulus/shared_controllers/*.js",
    "./app/assets/webpack/stimulus/shared_controllers/**/*.js",
    "./app/assets/webpack/mobile_app/stimulus_controllers/**/*.js",
    "./app/assets/webpack/payroll/stimulus_controllers/**/*.js",
    "./app/assets/webpack/desktop/stimulus_controllers/**/*.js",
  ],
  theme: {
    colors: {
      transparent: "transparent",
      current: "currentColor",
      btnSecondary: "#DBEEF5",
      btnSecondaryHover: "#bbe3f2",
      btnSecondaryTextDark: "#86CEFF",
      btnTertiaryTextHover: "#79c7fc",
      black: colors.black,
      white: colors.white,
      purple: colors.violet,
      rose: colors.pink,
      green: colors.emerald, // Min green-700 to be accessible on white.
      darkmode: {
        // WARNING: Dark mode colors are a WIP and subject to change. Please consult a designer before using.
        // Darkmode colors go from least contrast on black to most contrast on black.
        white: colors.white,
        blue: {
          "100": "#0c1c27",
          "200": "#10212D",
          "300": "#182C39",
          "400": "#193448",
          "500": "#17415E",
          "600": "#175682",
          "700": "#136FAE",
          "800": "#2fa1ee",
          "900": "#52B9FF",
        },
        green: {
          "100": "#161d1b",
          "200": "#192421",
          "300": "#1B2C27",
          "400": "#203730",
          "500": "#23433A",
          "600": "#2C6D5B",
          "700": "#2D9F7F",
          "800": "#43d0a8",
          "900": "#3BEDBA",
        },
        gray: {
          "25": "#030708",
          "50": "#070C0E",
          "75": "#0C1315",
          "100": "#141b1f",
          "200": "#1A2228",
          "300": "#222C35",
          "400": "#303B4B",
          "500": "#4A5B73",
          "600": "#7085A4",
          "700": "#A1AFC4",
          "800": "#CAD2DE",
          "900": "#E9ECF1",
        },
        red: {
          "100": "#271511",
          "200": "#331915",
          "300": "#461E1B",
          "400": "#531F1D",
          "500": "#682222",
          "600": "#A42828",
          "700": "#CC2724",
          "800": "#EA413E",
          "900": "#F76E69",
        },
        orange: {
          "100": "#1b1918",
          "200": "#30251C",
          "300": "#433119",
          "400": "#7C4C0E",
          "500": "#C17715",
          "600": "#E09A29",
          "700": "#F1B037",
          "800": "#FFC547",
          "900": "#FFD666",
        },
        rose: {
          "100": "#1D0F14",
          "200": "#2C0715",
          "300": "#3F0D21",
          "400": "#521933",
          "500": "#672847",
          "600": "#A22A65",
          "700": "#E02E86",
          "800": "#F3599E",
          "900": "#FF99C4",
        },
        purple: {
          "100": "#171023",
          "200": "#201631",
          "300": "#2B1F42",
          "400": "#372A58",
          "500": "#4F3984",
          "600": "#6947B8",
          "700": "#8261D1",
          "800": "#AA85FF",
          "900": "#CDB8FF",
        },
        accent: {
          "500": "#FBBF24",
          "600": "#FAB11E",
          "700": "#E1A404",
          "800": "#CA8A04",
          "900": "#A16207",
        },
      },
      accent: {
        "500": "#FBBF24",
        "600": "#FAB11E",
        "700": "#E1A404",
        "800": "#CA8A04",
        "900": "#A16207",
      },
      blue: {
        "50": "#ECFDFF",
        "75": "#CFF7FE",
        "100": "#B7F0FA",
        "200": "#A6EDFB",
        "300": "#74DEF6",
        "400": "#41CDEC",
        "500": "#1CB3D9",
        DEFAULT: "#1CB3D9",
        "600": "#169CC5",
        "700": "#197FA6" /* Accessible as text on white from here upwards */,
        "800": "#166F92" /* Accessible as text on gray-25 from here upwards */,
        "900": "#175C78",
        "950": "#104860",
      },
      orange: {
        "50": "#FFF5E0",
        "100": "#FFE6B3",
        "200": "#FFDA8F",
        "300": "#FFC34C",
        "400": "#FFB726",
        "500": "#FFAA00",
        DEFAULT: "#FFAA00",
        "600": "#FB9F00",
        "700": "#F57C00",
        "800": "#EF6C00" /* If you have to... */,
        "900": "#E65100" /* Technically not accessible, but ok to use */,
        "950": "#B23F00" /* Only truly accessibly orange on gray-25 or white, but more of a brown really */,
      },
      red: {
        "50": "#FFF6F5",
        "100": "#FEEEEC",
        "200": "#FDD7CE",
        "300": "#EDB2AB",
        "400": "#DD6255",
        "500": "#E11414" /* Accessible as text on gray-25 and white from here upwards */,
        DEFAULT: "#E11414",
        "600": "#C21414",
        "700": "#9F0E0E",
        "800": "#7C0303",
        "900": "#5C0000",
      },
      gray: {
        "25": "#F7F8FA",
        "50": "#F0F2F5",
        "75": "#E6E9ED",
        "100": "#DAE0E8",
        "200": "#CAD2DE",
        "300": "#BAC5D4",
        "400": "#A1B0C2",
        "500": "#8A9BAE",
        "600": "#708093" /* Minumum acceptable on gray-25 or white */,
        "700": "#536171" /* Accessible as text on gray-25 and white from here upwards */,
        "800": "#3C4754",
        "900": "#222B35",
      },
    },
    minHeight: {
      "0": "0px",
      "0.5": "0.125rem",
      "1": "0.25rem",
      "1.5": "0.375rem",
      "2": "0.5rem",
      "2.5": "0.625rem",
      "3": "0.75rem",
      "3.5": "0.875rem",
      "4": "1rem",
      "5": "1.25rem",
      "6": "1.5rem",
      "7": "1.75rem",
      "8": "2rem",
      "9": "2.25rem",
      "10": "2.5rem",
      "11": "2.75rem",
      "12": "3rem",
      "14": "3.5rem",
      "16": "4rem",
      "20": "5rem",
      "24": "6rem",
      "28": "7rem",
      "32": "8rem",
      "36": "9rem",
      "40": "10rem",
      "44": "11rem",
      "48": "12rem",
      "52": "13rem",
      "56": "14rem",
      "60": "15rem",
      "64": "16rem",
      "72": "18rem",
      "80": "20rem",
      "96": "24rem",
    },
    fontFamily: {
      body: ["Lato"],
    },
    boxShadow: {
      sm: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
      DEFAULT: "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
      md: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
      lg: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
      xl: "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
      "2xl": "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
      blur: "0 1px 8px rgba(0, 0, 0, 0.02), 0 8px 24px rgba(0, 0, 0, 0.04)",
      inner: "inset 0 2px 4px 0 rgba(0, 0, 0, 0.1)",
      none: "none",
      pop: "0.3rem 0.3rem 0.6rem #dfdfdf, -0.2rem -0.2rem 0.5rem #ffffff",
    },
    screens: {
      xs: "300px",
      ...defaultTheme.screens,
    },
    extend: {
      width: {
        fill: "-webkit-fill-available",
      },
      padding: {
        half: "0.125rem",
      },
      fontSize: {
        xxs: "0.625rem",
        button: "0.813rem",
      },
      margin: {
        half: "0.125rem",
      },
      screens: {
        // for taller phones (when stylings things to responsively fill the screen -> see clock in page)
        tall: { raw: "(min-height: 668px)" },
      },
      scale: {
        n1: "-1",
        "120": "1.2",
      },
    },
  },
  darkMode: "class" /* https://github.com/jjranalli/nightwind */,
  plugins: [
    require("tailwindcss-safe-area"),
    plugin(function ({ addUtilities }) {
      addUtilities({
        ".scrollbar-hide": {
          /* IE and Edge */
          "-ms-overflow-style": "none",
          /* Firefox */
          "scrollbar-width": "none",
          /* Safari and Chrome */
          "&::-webkit-scrollbar": {
            display: "none",
          },
        },
      })
    }),
    // Other plugins
    require("@tailwindcss/typography"),
    require("tailwindcss-animatecss")({
      classes: [
        "animate__animated",
        "animate__infinite",
        "animate__fadeIn",
        "animate__fadeOut",
        "animate__fadeInUp",
        "animate__fadeInDown",
        "animate__fadeInLeft",
        "animate__fadeInRight",
        "animate__fadeOutDown",
        "animate__bounceIn",
        "animate__zoomInUp",
        "animate__slideInUp",
        "animate__slideInLeft",
        "animate__slideOutDown",
        "animate__backInDown",
        "animate__flash",
        "animate__delay-1s",
        "animate__delay-2s",
        "animate__delay-3s",
        "animate__delay-4s",
        "animate__delay-5s",
        "animate__slower",
        "animate__slow",
        "animate__faster",
        "animate__swing",
      ],
      settings: {},
      variants: ["responsive", "hover", "reduced-motion"],
    }),
  ],
}
