import { Application } from "@hotwired/stimulus"
import * as Sentry from "@sentry/browser"

const application = Application.start()
application.warnings = true
application.debug = process.env.NODE_ENV === "development" // https://github.com/hotwired/stimulus/pull/354
application.handleError = (error, message, detail) => {
  Sentry.captureException(error)
}
window.Stimulus = application

window.addEventListener("turbo:frame-missing", (event) => {
  // Prevent setting "Content Missing"
  event.preventDefault()
  const frameId = event.target.id

  const frameTarget = document.querySelector(`#${frameId}`)

  if (frameTarget) {
    frameTarget.innerHTML = `
      <strong class="block font-bold rounded px-4 py-2 bg-red-500 dark:bg-darkmode-red-700 text-red-50 hover:text-red-700 dark:text-darkmode-red-100 dark:hover:text-darkmode-red-900">There was a problem loading this content.</strong>
    `
  }

  // Error must raise to send to sentry
  try {
    throw new Error(`turbo:frame-missing ${frameId} for url: ${event.detail.response.url}`)
  } catch (error) {
    Sentry.captureException(error)
  }
})

export { application }
