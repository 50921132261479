import { Controller } from "@hotwired/stimulus"
import { flash } from "mobile_app/helpers/flash"
import I18n from "helpers/i18n"

export default class extends Controller {
  static targets = ["message"]
  static values = { url: String }

  connect() {
    this.updateParams()
  }

  updateParams() {
    const all_hidden_content_inputs = this.element.querySelectorAll(
      "input[type=hidden][name^='communication_message[content]']"
    )
    all_hidden_content_inputs.forEach((input) => {
      input.value = this.messageTarget.value
    })
  }

  onSubmit(event) {
    if (this.messageTarget.value.trim() === "") {
      event.preventDefault()
      flash(I18n.t("js.communications.chat_about_it.content_blank"), "error")
    }
  }
}
